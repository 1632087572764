/* common.css
 * created 09/18/13
 --------------------------------------------------------- */

/* ----------------------------------------------------
 * Adjustments to Bootstap CSS to dePad the schtuff
 * ------------------------------------------------- */

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
}

.panel {
  margin-bottom: 10px;
  border-radius: 2px;
}

.panel-heading {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 2px 5px;
}

.panel-body {
  padding: 5px;
}

  .panel-default .panel-body {
    cursor: default;
  }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-left: 5px;
  padding-right: 5px;
}

.row {
  margin-right: -5px;
  margin-left: -5px;
}

.container,
.container-fluid {
  padding-right: 5px;
  padding-left: 5px;
}

.col-md-9.item-identifier {
  width: auto;
  max-width: 72%
}

/* ------------------------------------------------ */


/* Structural 
 --------------------------------------------------------- */
html, body {
  height: 100%;
  overflow-x: hidden;
  width: 100%;
}

body {
  color: #717171;
  font-family: "Open Sans",Arial,sans-serif;
  font-size: 13px;
  font-weight: 300;
}

a { 
  cursor: pointer;
}

textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input, .teaching-point, .question-stem, .caption-edit, .title-edit, .source-edit, .question-objective, .answer-editable, .image-description-edit  {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  color: #7f7f7f;
  line-height: 20px;
  padding: 4px 6px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s;
  display: block;
  font-size: 14px;
  font-weight: 300;
  resize: none;
  font-family: Arial;
  i {
    display: inline;
    background-image: none;
    width: inherit;
    height: inherit;
  }
}

#newUrlRefTitle {
  width: 100%;
  margin-bottom: 10px;
}

#newUrlRef {
  width: 100%;
}

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus, .teaching-point:focus, .question-stem:focus, .source-edit:focus, .title-edit:focus, .caption-edit:focus, .question-objective:focus, .image-description-edit:focus {
  border-color: rgba(82, 168, 236, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(82, 168, 236, 0.6);
  outline: 0 none;
}

textarea {
  height: auto;
}

.panel-body textarea {
  width:100%;
}

.panel-body .question-stem, .teaching-point, .caption-edit, .question-objective {
  width:100%;
  min-height: 50px;
  white-space: pre-wrap;
}

.question-stem, .teaching-point, .caption-edit, #caseDescription, #caseTeachingPoint, #caseHistory {
  width:100% !important;
  min-height: 50px;
  white-space: pre-line;
}

.question-stem i:not(.bsicon), .teaching-point i:not(.bsicon), .caption-edit i:not(.bsicon) {
  background-image: none !important;
  background-repeat: no-repeat;
  display: inline-block;
  width: initial;
}

.question-stem img, .teaching-point img, .caption-edit img {
  border: 1px solid #7f7f7f;
}

.context-menu-list {
  font-size: 14px !important;
  font-family: "Open Sans",Arial,sans-serif !important;
  font-weight: 300 !important;
  color: black !important;
}

.context-menu-item.hover {
  color: #fff !important;
  /*background-color: #39F !important;*/
  text-decoration: none !important;
  background-color: #428bca !important;
  cursor: pointer !important;
}


.wide {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  min-height:530px;
  z-index: 10;
  position: relative;

  &.stretch {
    height: 100%;
    overflow: hidden;
  }
}

.wide.stretch {

}

.secondary {
  width: 200px;
}

.wide > aside, .wide > section, #content aside {
    display: table-cell;
    float: none;
    height: 100%;
    padding: 0 !important;
    vertical-align: top;
    position: relative;
    z-index: 112;
}

.tall {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  height: 100%;
  position: relative;
  width: 100%;
}

.tall > footer + section {
  bottom: 50px;
}

.tall > header ~ section {
  top: 56px;
}

.tall > section, .tall > footer {
  bottom: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.tall > footer {
  top: auto;
  z-index: 1000;
}

.top {
  background: none repeat scroll 0 0 #e7e9eb;
  height: 57px;
  border-bottom: 1px solid #e5e5e5;

  .top-content {
    margin-left: 200px;
  }
}

#content {
  display: table-cell;
}

#main {
  margin-top: 58px;

  .header.top.pad, .content {
    min-width: 413px;
  }

  .content {
    display: block;
  }
}
  
  #right-col {
	border-left: 1px solid #f0f1f3;
	width: 18%;
	background: #f0f1f3;

    .top {
      background: none repeat scroll 0 0 #E7E9EB;
      border-left: 1px solid #DDDDDD;
      box-shadow: 1px 0 0 #F5F5F5 inset;
    }
  }

/* Framework components
 --------------------------------------------------------- */

#error-handler {
  position: absolute;
  top: 50px;
  right: 50px;
  left: 50px;
  z-index: 10000;
}

#error-handler tt {
  white-space: pre-wrap;
}

.logo {
  background: url("../images/a5logo.png") no-repeat scroll 13px 12px #D26F1A;
  height: 57px;
  border-bottom: 1px solid #3e424d;
}

#nav {
  transition: width 0.2s ease 0s;
  z-index: 1000;
}

#navsub {
  transition: width 0.2s ease 0s;
  z-index: 1000;

  ul li {
    .badge {
      background: none repeat scroll 0 0 #7A3434;
    }

    &.active .badge {
      background: none repeat scroll 0 0 #999;
    }

    .navlink-print-preview {
      width: 175px;
    }

    button.print-preview-dropdown {
      height: 38px;
      width: 25px;
      color: #efd2d2;
      border-color: #efd2d2;
      background-color: #a34848;
    }

    &.open > a {
      background-color: #953e3e;
    }

    ul.dropdown-menu {
      font-size: 13px;
      background-color: #7a3434;
      width: 200px;
    }
  }
}

.nav-primary > ul > li > a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
    border-top: 1px solid rgba(255, 255, 255, 0.05) !important;
    padding: 15px;
    position: relative;
    color: #eee;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s ease-in-out 0s;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nav-primary > ul > li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
}

.nav-primary > ul > li > a:hover, .nav-secondary > ul > li > a:hover {
  background-color: rgba(0,0,0,0.25);
}

.nav-primary > ul > li.active > a, .nav-primary > ul > li > a:focus, .nav-secondary > ul > li > a:focus, a.list-toggle.active {
  background-color: rgba(0,0,0,0.25);
  color: #FFF;
  text-shadow: 0 0 0;
}

.nav > li > a:hover, .nav > li > a:focus {
  background: inherit;
  outline: none;
}

.nav-secondary {
  position: absolute;
  bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
  border-top: 1px solid rgba(0, 0, 0, 0.08) !important;

  ul li {
    a {
      padding: 10px 10px 5px 15px;
      position: relative;
      color: #606778;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      transition: 0.2s ease-in-out 0s;
      font-size: 12px;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:first-child a {
      border-top: 1px solid rgba(255, 255, 255, 0.05) !important;
    }
    &:last-child a {
      padding-bottom: 10px;
    }
  }

  &.inner {
    bottom: 50px;
    border-bottom: 0;
    display: block;
    width: 100%;

    ul {
      li a {
        background-color: #994444;
      }

      &.nav {
        margin: 0;
      }
    }
  }
}

.secondary {
  &.aside.closed {
    width: 60px;

    .comments-inner {
      display: none !important;
    }

    button.print-preview-dropdown {
      display: none !important;
    }

    i.a.navsublink {
      margin-left: 0;
    }
  }

  ul {
    &.nav {
      margin: 5px 0 0 5px;

      li {
        i {
          padding-left: 4px;
        }
        &.active > a {
          color: white;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }

    &.tertiary {
      list-style: none outside none;
      margin-left: -10px;

      li {
        a {
          display: block;
          padding: 5px 0;

          i {
            margin: 2px 8px 0 0 !important;
          }
        }
      }
    }
  }
}

.bg-project ul.nav  {
  a {
    color: #D3C5E2;
    &:hover {
      color: white;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.bg-authoring ul.nav {
  a {
    color: #efd2d2;
    &:hover {
      color: white;
      background: #953e3e;
    }
  }

  li.active a {
    background: #7a3434;
  }

  li.sublink.active a {
    background: #953e3e;
  }
}

.bg-authoring ul.nav a, .bg-project ul.nav a {
  margin-left: -5px;
}
	

.footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
  a {
    &.toggle {
      width: 100%;
      display: block;
      padding: 18px 0 14px;
      i {
        margin: 0 auto;
      }
    }
    &:hover {
      i {
        background-position: -144px 0px;
      }
    }
    &.collapsed {
      i {
        background-position: -160px 0;
      }
      &:hover {
        i {
          background-position: -176px 0;
          }
      }
    }
  }
}

.header, .footer {
  min-height: 50px;
}

.sec-header {
  border-bottom: 1px solid rgba(255,255,255,0.1);
  padding-bottom: 15px;
  overflow: hidden;
  width: 200px;
}

.primary-foot {
  border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
  text-align: right;
  background: white;
}

/* Closed Nav Bar Elements
 --------------------------------------------------------- */
.closed {
  display: none;
  .logo {
    background: url("../images/a5logo-symbol.png") no-repeat scroll center #D26F1A;
    height: 57px;
    border-bottom: 1px solid #3e424d;
  }
  span, .list-toggle {
    display: none;
  }
  .nav a {
    padding-left: 20px;
  }
  .footer > a.toggle {
    width: 60px;
  }
  .sec-header {
    width: 58px;
    height: 57px;
    visibility: hidden;
  }
  &.secondary ul.tertiary {
    margin-left: -8px !important;
  }
  .navlink-print-preview:hover {
    width: 60px !important;
  }
}

/* Shared classes / styling
 --------------------------------------------------------- */
.bg-primary {
    background-color: #343843;
    color: #DBD8E9;
}

.bg-assignment {
  background-color: #a34848;
}
.bg-project {
  background-color: #a28db8;
}
.bg-product {
  background-color: #5eb7d3;
}
.bg-authoring {
  background-color: #a34848;
}

.bg-gradient {
    background-image: linear-gradient(to bottom, rgba(40, 50, 60, 0) 0px, rgba(40, 50, 60, 0.05) 100%);
    background-repeat: repeat-x;
    filter: none;
}

.aside-sm {
    width: 220px;
}

  .aside-sm.closed, .closed .aside-sm {
      width: 60px;
  }

.push-lft { float: left; }
.push-rgt   { float: right; }

.pad {
  padding: 10px;
}

.pad-sm {
  padding: 10px;
}

.scroll {
	overflow-x: hidden;
	overflow-y: auto;
}

.xtra-width { width: 101.5%; }
.xtra-suck-rgt { margin-right: 1.5%; }
.xtra-suck-lft { margin-left: -1.5%; }

.brd { border: 1px solid #d7dae3; }
.brd-bt { border-bottom: 1px solid #d7dae3; }
.brd-tp { border-top: 1px solid #d7dae3; }
.brd-lt { border-left: 1px solid #d7dae3; }
.brd-rt { border-right: 1px solid #d7dae3; }
.brd-r { border-radius: 3px; }

.mg5 { margin: 5px }
.mg5l { margin-left: 5px }
.mg5r { margin-right: 5px }
.mg5t { margin-top: 5px }
.mg5b { margin-bottom: 5px }

.cf:before, .cf:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.cf { zoom: 1; }
.cf:before,
.cf:after {
  content:"";
  display:table;
}
.cf:after { clear:both; }

/* Comment Drawer
 * ---------------------------------------------------------- */
.add-comment {
  position: relative;

  .new-comment-window {
    position: absolute;
    top: 30px;
    left: -1px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    z-index: 100;
    width: 250px;
    min-height: 80px;
    background: #fcfcfc;
    box-shadow: 0 5px 8px #ccc;

    textarea {
      width: 100%;
      min-height: 100px;
      font-size: 9pt;
      display: block;
      margin-bottom: 10px;
    }

    .cancel {
      float: left;
      font-size: 8pt;
      padding: 6px 0 0 2px;
    }

    .btn {
      font-size: 9pt;
    }
  }
}

.badge.new-comment-badge {
  background: none repeat scroll 0 0 #FF0000;
  font-size: 8pt;
  margin: 0 3px;
  padding: 2px 5px 3px;
  position: relative;
  top: -1px;
}

li.comment-list {
  display: none;
  background: #eee;
  border-right: 1px solid #A34848;
  padding: 0 10px;
  font-size: 9pt;
  max-height: 220px;
  overflow-y: auto;
  overflow-x: hidden;
  .comment {
    border-bottom: 1px dotted #ccc;
    padding: 10px 5px 10px 0;
  }
  .comment-date {
    font-size: 7pt;
    font-weight: 500;
    display: block;
  }
  .comment-text {
    color: #272A32;
    font-family: Arial;
    font-size: 9pt;
    line-height: 14px;
    white-space: pre-wrap;
  }
}

#comments {
  textarea.new-comment {
    border: 0;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    font-size: 9pt;
    width: 199px;
    height: 50px;
    padding: 10px 5px 0 0;
  }
  li span.meta {
    background: none repeat scroll 0 0 #888;
    border-left: 1px solid #EEEEEE;
    border-right: 1px solid #A34848;
    color: #eee;
    display: block;
    font-size: 8pt;
    padding-left: 3px;
  }
  button {
    border: 0 none;
    border-radius: 0;
    display: block;
    font-size: 10pt;
    margin: 0 auto;
    width: 100%;
    padding: 6px 0;
  }

}

a.comment-drawer.active {
    color: white !important;
    background: transparent !important;
}


/* A5 glyphs
 --------------------------------------------------------- */
i:not(.bsicon), i:not(.bsicon).a {
  background-image: url(../images/a5glyphs.png);
  background-repeat: no-repeat;
  display: block;
  width: 16px;
  height: 16px;
}

  i.a.navprimary.workboard { margin:3px 10px 0 0; background-position: 0 0; }
    li.active > a > i.a.navprimary.tasks, li.active > a > i.a.navprimary.workboard { background-position: -16px 0; }
  i.a.navprimary.assignment { margin:2px 10px 0 0; background-position: -32px 0; }
    li.active > a > i.a.navprimary.assignment { background-position: -48px 0; }
  i.a.navprimary.project { margin:2px 10px 0 0; background-position: -64px 0; }
    li.active > a > i.a.navprimary.project { background-position: -80px 0; }
  i.a.navprimary.product { margin:2px 10px 0 0; background-position: -96px 0; }
    li.active > a > i.a.navprimary.product { background-position: -112px 0; }

  i.a.navprimary.tasks { margin:3px 8px 0 0; }
  i.a.navprimary.project { margin: 2px 8px 0 0; }
  i.a.navprimary.authoring { margin:0 8px 0 0; }
  i.a.navsublink.authoring { margin:0 8px 0 20px;}

  i.a.navsecondary.profile { margin:0px 10px 0 0; background-position: 0 -16px; }
    li.active > a > i.a.navsecondary.profile { background-position: -16px -16px; }
  i.a.navsecondary.help { margin:0px 10px 0 0; background-position: -32px -16px; }
    li.active > a > i.a.navsecondary.help { background-position: -48px -16px; }
  i.a.navsecondary.logout { margin:0px 10px 0 0; background-position: -64px -16px; }
    li.active > a > i.a.navsecondary.logout { background-position: -80px -16px; }

  i.a.collapse-nav { background-position: -128px 0px; }

   i.search { background-position: 0 -48px; }
   i.tag { background-position: -48px -48px; }
   
   i.sec-open { background-position: -16px -48px; }
   i.sec-closed { background-position: -32px -48px; }
    
    
/* Item
--------------------------------------------------------- */
.item-date-due {
	font-size: 9pt;
	font-weight: 800;
	color: #343843;
}

.item-tags {
  margin-top: 2px;
}

a.item-tag {
  display: block;
  float: left;
  border: 1px solid #bbb;
  border-radius: 2px;
  color: #999;
  background: white;
  padding: 1px 3px;
  margin: 1px;
  font-size: 8pt;
  &:hover {
    color: #343843;
    text-decoration: none;
    border-color: #343843;
  }
  &.on, &.on:hover {
    text-decoration: none;
    border-color: #bbbbbb;
    border-color: #343843;
    background: #343843;
    color: white;
  }
}

.item-tag {
  display: block;
  float: left;
  border: 1px solid #bce8f1;
  border-radius: 2px;
  color: #31708f;
  background: #d9edf7;
  padding: 1px 3px;
  margin: 1px;
  font-size: 8pt;
}

#filterMessage span {
  margin-left: 3px;
}

.item-tag-display {
  background: #343843;
  color: white;
  border-radius: 2px;
  font-size: 8pt;
  line-height: 1 !important;
  height: auto !important;
  padding: 3px;
  font-weight: 500;

  .tag-kill {
    border-left: 1px solid #FFFFFF;
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
    padding: 0 2px 0 5px;
  }
}

.no-items {
  font-size: 9pt;
  font-weight: 500;
  color: #aaa;
}

.filter-count {
  margin-left: 10px;
  font-weight: 300;
  color: #cfd1d6;
}

.data-header.open .filter-count {
  color: #6e7689;
}
	
.data-count-pill {
  background: none repeat scroll 0 0 #E5AFAF;
  border-radius: 9px;
  padding: 0 6px 2px;
  color: #FFFFFF;
  display: inline;
  font-size: 10px;
  font-weight: 500;
/*		margin: 0 0 0 5px;*/
  text-align: center;
}

.data-header.open .data-count-pill {
  background: #a34848;
}

.data-header .list-title {
  padding-right: 5px;
}

/* Text
--------------------------------------------------------- */
.sugar-text {
  white-space: pre-wrap;
  padding: 5px;
  i {
    display: inline-block;
    background-image: none;
    width: initial;
    height: initial;
  }
}

h1 {
  font-family: 'Open Sans';
  color: white;
  font-size: 22px;
  font-weight: 300;
  margin:0;
  padding: 0;
  &.item-name {
    font-size: 12pt;
    span.type {
      font-size: 9pt;
      display: block;
      opacity: 0.6;
      padding-bottom:2px
    }
  }
  &.sub {
    padding: 15px 0 2px 10px;
  }
}

.content h1 {
  color: inherit;
  border-bottom:1px solid #ccc;
  padding-bottom: 5px;
  margin: 0 0 10px;
  font-size: 18px;
}

#right-col {
  h1 {
    color: #343843;
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 28px;
  }

  h2 {
    font-family: 'Open Sans';
    font-size: 15pt;
    padding-bottom: 5px;
    margin-bottom: 5px;
    color: #343843;
    margin-top:0;
    i {
      margin-right:2px;
    }
    .details {
      font-weight: 700;
      font-size: 12pt;
      line-height: 18px;
      margin: 10px 0 30px 16px;
      span {
        display: block;
        font-weight: 300;
        font-size: 10pt;
      }
    }
    &.filter-group {
      background: none repeat scroll 0 0 lightslategray;
      border-radius: 3px;
      color: #FFFFFF;
      font-size: 9pt;
      margin-top: 10px;
      padding: 4px;
      text-transform: uppercase;

      a.toggle-filter-list {
        color: white;
        text-decoration: none;
        display: block;

        span {
          display: inline-block;
        }
      }
    }
  }
}


.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #f5f5f5;
}

.meta {
  color: #aaa;
  font-size: 8pt;
  display: block;
}

.btn {
  border-radius: 3px;
  padding: 4px 6px;
  font-size: 10pt;
  font-weight: 500;
  i {
    margin-right: 1px;
  }
  &:hover {
    box-shadow: inset 1px 1px 1px rgba(0,0,0,0.1);
  }
}
  
.btn-big {
  font-size: 20px;
  color: white;
  font-weight: 400;
  line-height: 1;
  display: block;
  padding: 2px 8px 4px;
  border-radius: 3px;
  background-color: rgba(255,255,255,0.3);
  transition: 0.2s ease-in-out 0s;
  &.add {
    position: relative;
    top: 15px;
    right: 10px;
  }
  &:hover {
    background-color: rgba(255,255,255,0.2);
    text-decoration: none;
    color: white
  }
}

.btn-grp {
  .btn {
    float: left;
    margin:0 3px 0 0;
  }
    .btn-divider {
      float: left;
      display: block;
      height: 29px;
      width:1px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      margin: 0 5px;
  }
}

.btn-grp > *:first-child, .btn-grp > *:last-child, .btn-grp > *:first-child > a, .btn-grp > *:last-child > a {
  margin-right:0;
}

.btn-right {
  float: right;
}

.or-cancel {
  float: left;
  margin: 4px 0 0 4px;
  color: #6e7689;

  a {
    margin-left:3px;
    text-decoration: underline;
    color: #6e7689;
  }
}
	
.sec-body {
  border-radius: 0 0 3px 3px;
}

.data-header {
  border-color: #e0e0e0;
  background-color: #F0F1F3;
  padding: 5px 0;
  a {
    color: #2b2e37 !important;
    font-size: 10pt;
    font-weight: 600;
    display: block;
    margin-left: 16px;
    &:hover {
      text-decoration: none;
    }
  }
  .item-bulk {
    display: none;
  }
  &.open {
    border-color: #c2c2c2;
    .item-bulk a {
      color: #428BCA !important;
      font-size: 8pt;
    }
  }
}

.data-body {
  padding-bottom: 10px;

  .item-area {
    overflow: hidden;
  }
}
	
.data-header i, .item-link i {
    margin: 2px 0 0;
}
	
.dropdown {
  display: block;
  min-width: 140px;
  a.primary {
    color: #7f7f7f;
    display: block;
    font-size: 8pt;
    padding: 5px 10px;
    transition: all 0.218s ease 0s;
    border: 1px solid rgba(0,0,0,0.15);
    background: #f5f5f5;
    border-radius: 2px;
    margin-left: 6px;
    text-decoration: none;
    &.disabled > i {
      visibility: hidden;
    }
  }
  .sub {
    min-width: 0;
  }
}

.dropdown-menu > li > a {
  white-space: normal;
  &:hover {
    color: #fff !important;
    /*background-color: #39F !important;*/
    text-decoration: none !important;
    background-color: #428bca !important;
    cursor: pointer !important;
  }
}

.dropdown a.primary:hover, .dropdown.open a.primary, .dropdown.open a.primary:hover {
  box-shadow: 0 1px 0 rgba(0,0,0,0.15);
  border-color: #c6c6c6;
  color: #444;
}

.dropdown a.primary.disabled, .dropdown a.primary.disabled:hover {
  color: #aaa !important;
  border: 1px solid rgba(0,0,0,0.05) !important;
  box-shadow: 0 0 0 !important;
}

ul.sub {
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 2px 0 0 0;
  box-shadow: 0 0 3px #CCCCCC;
  width: 100%;
  margin-left: 7px;
  position: absolute;
  z-index: 101;
  top: auto;
  li {
    list-style: none;
    a {
      line-height: 20px;
      font-size: 8pt;
      color: #6e7689;
    }
    &.active a {
      color: #2084C4;
      background: none;
      font-weight: 600;
    }
  }
}

ul.sub li > a:hover, ul.sub li.active > a:hover {
  background: none;
  text-decoration: underline;
  color: inherit;
}

.modal-body .dropdown {
  width: 430px;
  ul.sub {
    width: 438px;
  }
}

.tx {
  transition: all 0.218s ease 0s;
}

::-webkit-input-placeholder {
  color: #aaa;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 9px;
  border: 1px solid rgba(0,0,0,0.1);
}
::-webkit-scrollbar:horizontal {
  -webkit-appearance: none;
  height: 9px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal
{
  border-radius: 4px;
  background-color: #bbb;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:horizontal:hover
{
  background-color: #999;
}

:-moz-placeholder {
  color: #aaa;
}
::-moz-placeholder {
  color: #aaa;
}
:-ms-input-placeholder {
  color: #aaa;
}

.loader-xl {
  width: 128px;
  height: 128px;
  display: block;
  margin: 150px auto;
  color: rgba(255,255,255,0.9);
  text-align: center;
  .msg {
    font-weight: bold;
    margin-top: 10px;
  }
}

.loader-lg {
  width: 60px;
  height: 60px;
  display: block;
  background: url(../images/loader-lg.gif) center no-repeat;
  margin: 0 auto;
}

.loader-sm {
  background: url("../images/loader-sm.gif") center no-repeat;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.loader-sm-centered {
  background: url("../images/loader-sm.gif") center no-repeat;
  display: block;
  width: 16px;
  height: 16px;
  margin: 0 auto;
}

.alert.alert-block {
  background: #f5f5f5;
  color: #bbb;
  border-color: rgba(0,0,0,0.075);
}

.alert.message {
  height: 28px !important;
  padding: 3px !important;
}

[class^="fa-"], [class*=" fa-"] {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

.popover-content {
  font-size: 8pt;
}

/* Diffing
--------------------------------------------------------- */

.diff-same {
  background-color: #d9edf7;
}

.diff-changed {
  background-color: #fcf8e3;
}

.diff-added {
  background-color: #d7ffd1;
}

.diff-removed {
  background-color: #ffe2d6;
}

.work-item-type {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border-right: 1px solid #DDDDDD;
  color: #222;
  font-size: 8pt;
  font-weight: 800;
  margin: 0 3px 0 0;
  padding: 0 4px 0 0;
  text-transform: uppercase;
}

.clear-all {
  background: none repeat scroll 0 0 #428BCA;
  border-radius: 2px;
  color: #FFFFFF;
/*    display: block;*/
  font-size: 7pt;
  margin: 5px 2px 0 0;
  padding: 2px 4px 3px;
  text-transform: uppercase;
  display: none;
}

.clear-all:hover {
  color: white;
  text-decoration: none;
  background: #235c8e;
}

.work-item-info {
  font-size: 8pt;
  line-height: 16px;
  color: #444;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  opacity: .45;
}

.p5 { padding: 5px; }
.p10 { padding: 10px; }
.p20 { padding: 20px; }
.p30 { padding: 30px; }
.p40 { padding: 40px; }
.p50 { padding: 50px; }

.p5t { padding-top: 5px; }
.p5r { padding-right: 5px; }
.p5b { padding-bottom: 5px; }
.p5l { padding-left: 5px; }

.p10t { padding-top: 10px; }
.p10r { padding-right: 10px; }
.p10b { padding-bottom: 10px; }
.p10l { padding-left: 10px; }

.p20t { padding-top: 20px; }
.p20r { padding-right: 20px; }
.p20b { padding-bottom: 20px; }
.p20l { padding-left: 20px; }

.p30t { padding-top: 30px; }
.p30r { padding-right: 30px; }
.p30b { padding-bottom: 30px; }
.p30l { padding-left: 30px; }

.p40t { padding-top: 40px; }
.p40r { padding-right: 40px; }
.p40b { padding-bottom: 40px; }
.p40l { padding-left: 40px; }

.p50t { padding-top: 50px; }
.p50r { padding-right: 50px; }
.p50b { padding-bottom: 50px; }
.p50l { padding-left: 50px; }

.m5 { margin: 5px; }
.m10 { margin: 10px; }
.m20 { margin: 20px; }
.m30 { margin: 30px; }
.m40 { margin: 40px; }
.m50 { margin: 50px; }

.m5t { margin-top: 5px; }
.m5r { margin-right: 5px; }
.m5b { margin-bottom: 5px; }
.m5l { margin-left: 5px; }

.m10t { margin-top: 10px; }
.m10r { margin-right: 10px; }
.m10b { margin-bottom: 10px; }
.m10l { margin-left: 10px; }

.m20t { margin-top: 20px; }
.m20r { margin-right: 20px; }
.m20b { margin-bottom: 20px; }
.m20l { margin-left: 20px; }

.m30t { margin-top: 30px; }
.m30r { margin-right: 30px; }
.m30b { margin-bottom: 30px; }
.m30l { margin-left: 30px; }

.m40t { margin-top: 40px; }
.m40r { margin-right: 40px; }
.m40b { margin-bottom: 40px; }
.m40l { margin-left: 40px; }

.m50t { margin-top: 50px; }
.m50r { margin-right: 50px; }
.m50b { margin-bottom: 50px; }
.m50l { margin-left: 50px; }



/* Typeahead Styling */
.tt-dropdown-menu {
  background: black;
  color: white;
  border-radius: 0 0 4px 4px;
  height: 275px;
  width: 100%;
  overflow-y: auto;

  &.open-top {
    bottom: 100%;
  }
  p {
    padding: 5px 10px 8px;
    margin: 0;
    cursor: pointer;
    border-bottom: 1px solid #333;
    &:hover {
      background: #333;
    }
    &.active {
      background: #333;
    }
  }
}

.tt-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: lightgray;
}

.tt-suggestions > .tt-suggestion:last-child > p {
  border:0;
  border-radius: 0 0 4px 4px;
}

.project-list {
  padding: 10px 0 0 0;
  background: rgba(0, 0, 0, 0.25);
  li {
    list-style: circle;
  }
  a {
    font-size: 8pt;
    font-weight: 700;
    color: #ddd;
    line-height: 22px;
    &:hover {
      color: white;
    }
  }
  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
  &.project-item {
    border-top:1px solid #ccc;
  }
}

.projectnav {
  padding-left: 32px;
  &.active {
    background: #fff;
    a {
      color: #272A32;
    }
  }
}

.nav .list-toggle {
  width: 23px;
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, 0.05) !important;
  padding-left: 0;
  padding-right: 0;
}

.closed .nav-primary-project {
  width: auto !important;
}

.pinnable {
  cursor: pointer;
  padding: 5px 10px;
  color: #4d4d4d
}

.pinnable.pinned {
  color: #d3d3d3;
}

.project-holder.active .pinnable {
  color: #d3d3d3;
}

.project-holder.active .pinnable.pinned {
  color: #4d4d4d;
}

/* Arrow Glyphs */

img.white-open, img.white-solid, img.white-curve, img.white-curve-up,
img.black-open, img.black-solid, img.black-curve, img.black-curve-up,
img.cyan-open, img.cyan-solid, img.cyan-curve, img.cyan-curve-up {
  width: 21px;
  height: 14px;
  background-image: url(../images/annotation-arrow-glyph.png);
}

img.white-open {
  background-position: -1px -1px;
}

img.white-solid {
  background-position: -22px -1px;
}

img.white-curve {
  background-position: -43px -1px;
}

img.white-curve-up {
  background-position: -43px -1px;

  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

img.black-open {
  background-position: -1px -15px;
}

img.black-solid {
  background-position: -22px -15px;
}

img.black-curve {
  background-position: -43px -15px;
}

img.black-curve-up {
  background-position: -43px -15px;

  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

img.cyan-open {
  background-position: -1px -29px;
}

img.cyan-solid {
  background-position: -22px -29px;
}

img.cyan-curve {
  background-position: -43px -29px;
}

img.cyan-curve-up {
  background-position: -43px -29px;

  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}


/* Image uploader styles */

.upload-actions {
  display: inline-block;
  padding: 10px;
  margin-left: 10px;
  border-left: 1px solid #ddd;
}

.error {
  color: red;
  font-weight: bold;
  white-space: pre-wrap;
}

.choose-files-wrapper {
  display: table;
  position: relative;
  width: 150px;
  height: 150px;
  border: 3px dashed #428BCA;
  text-align: center;
  color: #428BCA;
  font-size: 12pt;
  margin: 0 auto;
  cursor: pointer;
  p {
    display: table-cell;
    vertical-align: middle;
  }
  &:hover {
    background-color: rgba(66, 139, 202, 0.10);
  }
}

.choose-files-input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}

.file-info-table {
  table-layout: fixed;
  td {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

ul.character-map div {
  font-family: sans-serif;
  font-size: 10pt;
  color: black;
  text-align: center;

  .close {
    width: 27px;
    height: 29px;
  }

  .map .special-char{
    width: 26px;
    height: 26px;
    line-height: 26px;
    border: 1px solid black;
    &:hover {
      cursor: pointer;
    }
  }
}

*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

#sort-links, #sort-links .btn {
  font-size: 8pt;
}

#routing-dropdown .loader-sm {
  width: 100%;
}

.caseImageBrowser {
  .topicPane {
    float: left;
    width: 250px;
    margin-right: 20px;
    height: 600px;
    overflow: hidden;
    max-height: 450px;
  }

  #dxInput {
    width: 100%;
  }

  .casePane {
    float: left;
    width: 585px;
    height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 450px;
    .caseList {
      margin-bottom: 0px;
    }
    a input {
      position: absolute;
      top: 0px;
      right: 3px;
    }
  }

  .dxList {
    overflow-y: auto;
    height: 543px;
    max-height: 320px;
  }

  .dxSelect {
    width: 100%;
  }

  .chosen-container .chosen-results {
    max-height: 314px !important;
  }

  .images .image {
    position: relative;
    border-width: 1px;
    padding: 15px;
    width: 220px;
    height: 220px;
  }

  .popover {
    max-width: 400px;
    .popover-content {
      overflow-y: auto;
      font-size: 8pt;
      color: #444;
      dt {
        width: 80px;
      }
      dd {
        margin-left: 100px;
      }
    }
  }

  .case .upper {
    margin-bottom: 10px;
  }
}

.caseList .numberOfImages {
  font-size: 10pt;
}

#caseImageModal #selectedImagesLabel {
  line-height: 1.42857143;
  margin-right: 20px;
  font-style: italic;
}

.workboard-item-comment {
  i {
    cursor: pointer;
    color: #d3d3d3;
  }
  &.open {
    i {
      color: darkslategrey;
    }
  }
}

.comment {
  padding: 0;
  max-width: 275px;

  .comment-date {
    padding: 3px;
    font-weight: 600;
    font-size: 10px;
  }
  .comment-date-flyout {
    padding: 0 0 3px 32px;
    font-weight: 600;
    font-size: 10px;
  }
  .commentText {
    padding: 5px 0 5px 32px;
    height: 80px;
  }
}

.comment-task {
    min-width: 275px;
}

li.more-info {
    padding: 5px 0;
}

li.bottom-border {
    border-bottom: 1px solid #e9ebf0;
}

.more-info-icon {
    padding: 3px 10px 0 7px;
}

.topicTreeSelect {
  width: 100%;
}

.extraInfo {
  font-family: Arial;
  display: inline-block;
}

.text-larger {
  font-size: 1.1em;
}

.cursor-pointer {
  cursor: pointer;
}

.workflow-btn {
  margin-left: 5px;
}

sub {
  vertical-align: sub;
}
sup {
  vertical-align: super;
}
.finalization-status-div {

      width: 128px;
      height: 20px;
 	  float: left;
 	  top: -12px;
 	  position: relative;
      .image-footer{
        text-align: center;
        background-color: transparent;
        width: 108px;
        height: 18px;
        zoom: 1;
        }

    }
.finalization-img {	
	background: url(../images/checkbox.svg) no-repeat;
    width: 16px;
    height: 16px;
}
.finalized-checkbox{
	text-align: center; 
	vertical-align: middle;
	padding-left:25px;
} 
.finalization-preview-div {
      margin: 5px;
  	  width: 155px;
      height: 20px;
 	  float: left;
 	  top: -18px;
 	  position: relative;
      .image-footer {
        text-align: center;
        background-color: transparent;
        border-radius: 2px 2px 0 0;
		width: 133px;
        height: 18px;
        zoom: 1;
        }
    }
.finalization-img-slot{
   	background: url(../images/checkbox.svg) no-repeat;
    width: 16px;
    height: 16px;
    position: relative;
}
.project-pad-left{
	padding-left:20px;
}
.mcq-image-title{
	color: white;
    width: 100%;
    font-size: 15px;
    padding-left: 15px;
    padding-bottom: 5px;
}